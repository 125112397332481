import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";

const Page = () => {
  return (
      <LessonLayout
        current_step={5}
        lesson={"Lesson 1"}
        color={"hs"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 5</H2>

          <p>The first category of the rubric includes 5 statements in the "Criteria" column. How do these statements help you judge a website?</p>
          <p>You can think of the criteria on the rubric as a way of keeping track of evidence, in this case about a website's quality. You can then use this evidence to help you make a quick decision about the website and whether it is worth spending the time to read it.</p>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
